import React, { useState,useEffect } from 'react';
import axios from 'axios';
import './Home.css';



function FileUpload() {
    const [file, setFile] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(''); // Notu saklayacak state
    const [name, setName] = useState(''); // Notu saklayacak state
    const [type, setType] = useState(1); // Notu saklayacak state
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Popup'ın açık veya kapalı olduğunu izleyen state
    

        // Notu güncelleyen fonksiyon
    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    // Popup'ı açan fonksiyon
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    // Popup'ı kapatan fonksiyon
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    // Notu gönderen fonksiyon
    const sendNote = () => {
        // Burada notu gönderme işlemini gerçekleştirebilirsiniz
        console.log('Not gönderildi:', note);
        // Notu sıfırla ve popup'ı kapat
        setNote('');
        setIsPopupOpen(false);
    };
    
    const handleFileChange1 = (event) => {
        setFile(event.target.files[0]);
        setType("1");
    };

    const handleFileChange2 = (event) => {
        setFile(event.target.files[0]);
        setType("2");
    };
    const handleSubmit = (event) => {
        if (!file) {
            setError('Lütfen bir dosya seçin');
            return;
        }

        setLoading(true); // Yükleme durumunu başlat

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        axios.post('https://development.codelisa.com.tr/cds-storage/upload', formData)
            .then((response) => {
                if (response.status === 200) {
                    setResponse('Yükleme Başarılı!');
                    setFile(null)
                    setError(null);
                } else {
                    setResponse(null);
                    setFile(null)
                    setError('Bir hata oluştu: ' + response.status);
                }
            })
            .catch((error) => {
                console.error('İstek hatası:', error); // Hata durumunda hatayı konsola yazdır
                setResponse(null);
                setError('İstek hatası: ' + error.message);
            })
            .finally(() => {
                setLoading(false); // Yükleme durumunu sonlandır

                // Yükleme veya hata mesajını 10 saniye sonra temizle
                setTimeout(() => {
                    setResponse(null);
                    setError(null);
                }, 10000);
            });
    };

    const handleSubmitNote = (event) => {
        
        const formData = new FormData();
        formData.append('text', name + "-" + note);
        formData.append('type', "3");

        axios.post('https://development.codelisa.com.tr/cds-storage/upload', formData)
            .then((response) => {
                if (response.status === 200) {
                    setResponse('Yükleme Başarılı!');
                    setIsPopupOpen(false);
                    setError(null);
                } else {
                    setResponse(null);
                    setIsPopupOpen(false);
                    setError('Bir hata oluştu: ' + response.status);
                }
            })
            .catch((error) => {
                console.error('İstek hatası:', error); // Hata durumunda hatayı konsola yazdır
                setResponse(null);
                setIsPopupOpen(false);
                setError('İstek hatası: ' + error.message);
            })
            .finally(() => {
                setIsPopupOpen(false);
                setLoading(false); // Yükleme durumunu sonlandır

                // Yükleme veya hata mesajını 10 saniye sonra temizle
                setTimeout(() => {
                    setResponse(null);
                    setError(null);
                }, 10000);
            });
    };

    return (
        <div className="container">
            <div className="header">
                <h1 className={"name"}>Selim & Ece</h1>
                <h3 className={"tarih"} >27/04/2024</h3>
            </div>

            <div className="content">
                <label htmlFor="file-upload" className="file-upload-label">Fotoğraf Yükle</label>
                <input type="file" id="file-upload" className="file-upload-input"  onChange={handleFileChange1} />
                <br />
                <label htmlFor="file-upload" className="file-upload-label">Gözlükle Fotoğraf Yükle</label>
                <input type="file" id="file-upload2" className="file-upload-input" onChange={handleFileChange2} />
                <br />
                <button onClick={openPopup} className="note-button">Bize Bir Not Bırak</button>
                {file ? (
                <button onClick={handleSubmit} className="upload-button">Yükle</button>
                ): (
                    <div></div>
                )}
            </div>

            {/* Popup penceresi */}
            {isPopupOpen && (
                <div className="popup">
                    <span className="close" onClick={closePopup}>&times;</span>
                    <textarea
                        className="name-input"
                        placeholder="Buraya adınızı girin..."
                        value={name}
                        onChange={handleChangeName}
                    ></textarea>
                    <textarea
                        className="note-input"
                        placeholder="Buraya bir not girin..."
                        value={note}
                        onChange={handleChange}
                    ></textarea>
                    <button className={"notegönder"} onClick={handleSubmitNote}>Notu Gönder</button>
                </div>
            )}
   
          

            {loading &&  <div className="loading1div">
                <p className="loading1">Lütfen bekleyin, yükleme yapılıyor...</p>
            </div>}

           
            
            {response &&  <div className="loading1div">
                <p className="loading1">{response}</p>
            </div> }
            

            {error &&  <div className="loading1div">
                <p className="loading1">{error}</p>
            </div>}
        </div>
    );
}

export default FileUpload;